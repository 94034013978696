import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";
import checkLogin from "@/middleware/check-login";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/home/index.vue"),
      meta: {
        pageTitle: "Trang chủ",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Trang chủ",
            active: true,
          },
        ],
      },
    },
    // product inventory manager
    {
      path: "/product-inventory-manager",
      name: "product-inventory-manager",
      component: () => import("@/views/productInventoryManager/index.vue"),
      meta: {
        pageTitle: "Quản lý Kho thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh sách thuốc",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product-inventory/:master_id",
      name: "ProductInventoryDetail",
      component: () =>
        import("@/views/productInventoryManager/ProductInventoryDetail.vue"),
      meta: {
        pageTitle: "Chi tiết Thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh sách",
            active: false,
            to: "/product-inventory-manager",
          },
          {
            text: "Chi tiết",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product-inventory-manager/add-receiving",
      component: () => import("@/views/receivingManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm phiếu nhập",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh sách Thuốc",
            to: "/product-inventory-manager",
            active: false,
          },
          {
            text: "Thêm phiếu nhập",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product-inventory-manager/add-saling",
      component: () => import("@/views/salingManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm phiếu xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh sách Thuốc",
            to: "/product-inventory-manager",
            active: false,
          },
          {
            text: "Thêm phiếu xuất",
            active: true,
          },
        ],
      },
    },
    // product manager
    {
      path: "/product-manager",
      name: "product-manager",
      component: () => import("@/views/pharmaProduct/productManager/index.vue"),
      meta: {
        pageTitle: "Danh sách Thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh sách Thuốc",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product-manager/add",
      name: "add-product",
      component: () =>
        import("@/views/pharmaProduct/productManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm Thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh mục Thuốc",
            active: false,
            to: "/product-manager",
          },
          {
            text: "Thêm Thuốc",
            active: true,
          },
        ],
      },
    },
    {
      path: "/product-manager/update/:id",
      name: "update-product",
      component: () =>
        import("@/views/pharmaProduct/productManager/add/index.vue"),
      meta: {
        pageTitle: "Cập nhật Thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh mục Thuốc",
            active: false,
            to: "/product-manager",
          },
          {
            text: "Cập nhật Thuốc",
            active: true,
          },
        ],
      },
    },
    {
      path: "/category-manager",
      name: "category-manager",
      component: () =>
        import("@/views/pharmaProduct/productCategory/index.vue"),
      meta: {
        pageTitle: "Loại thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Loại thuốc",
            active: true,
          },
        ],
      },
    },
    {
      path: "/brand-manager",
      name: "brand-manager",
      component: () => import("@/views/pharmaProduct/Supplier/index.vue"),
      meta: {
        pageTitle: "Nhà cung cấp",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhà cung cấp",
            active: true,
          },
        ],
      },
    },
    {
      path: "/country-manager",
      name: "country-manager",
      component: () => import("@/views/pharmaProduct/Country/index.vue"),
      meta: {
        pageTitle: "Nước sản xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nước sản xuất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/manufacture-manager",
      name: "manufacture-manager",
      component: () => import("@/views/pharmaProduct/Manufacture/index.vue"),
      meta: {
        pageTitle: "Hãng sản xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Hãng sản xuất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/use-type",
      name: "use-type",
      component: () => import("@/views/pharmaProduct/pharmaUseType/index.vue"),
      meta: {
        pageTitle: "Đường dùng thuốc",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Đường dùng thuốc",
            active: true,
          },
        ],
      },
    },
    {
      path: "/unit-manager",
      name: "unit-manager",
      component: () => import("@/views/pharmaProduct/UnitManager/index.vue"),
      meta: {
        pageTitle: "Đơn vị",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Đơn vị",
            active: true,
          },
        ],
      },
    },
    {
      path: "/ingredient-manager",
      name: "ingredient-manager",
      component: () =>
        import("@/views/pharmaProduct/ActiveIngredient/index.vue"),
      meta: {
        pageTitle: "Hoạt chất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Hoạt chất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/group-manager",
      name: "group-manager",
      component: () => import("@/views/pharmaProduct/GroupManager/index.vue"),
      meta: {
        pageTitle: "Nhóm quản lý",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhóm quản lý",
            active: true,
          },
        ],
      },
    },
    {
      path: "/research-manager",
      name: "research-manager",
      component: () => import("@/views/pharmaProduct/GroupResearch/index.vue"),
      meta: {
        pageTitle: "Nhóm nghiên cứu",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhóm nghiên cứu",
            active: true,
          },
        ],
      },
    },
    {
      path: "/group-pathological",
      name: "group-pathological",
      component: () =>
        import("@/views/pharmaProduct/GroupPathologicals/index.vue"),
      meta: {
        pageTitle: "Nhóm bệnh lý",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhóm bệnh lý",
            active: true,
          },
        ],
      },
    },
    {
      path: "/group-abcven",
      name: "group-abcven",
      component: () => import("@/views/pharmaProduct/GroupABCVEN/index.vue"),
      meta: {
        pageTitle: "Nhóm ABC/VEN",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhóm ABC/VEN",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pharmacological",
      name: "pharmacological",
      component: () =>
        import("@/views/pharmaProduct/Pharmacologicals/index.vue"),
      meta: {
        pageTitle: "Nhóm dược lý",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhóm dược lý",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sub-pharmacological",
      name: "sub-pharmacological",
      component: () => import("@/views/pharmaProduct/productBrand/index.vue"),
      meta: {
        pageTitle: "Nhóm tiểu dược lý",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Nhóm tiểu dược lý",
            active: true,
          },
        ],
      },
    },

    {
      path: "/medicine-type",
      name: "medicine-type",
      component: () => import("@/views/pharmaProduct/productBrand/index.vue"),
      meta: {
        pageTitle: "Loại thuốc theo QĐ3618",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Loại thuốc theo QĐ3618",
            active: true,
          },
        ],
      },
    },
    {
      path: "/bidding-type",
      name: "bidding-type",
      component: () => import("@/views/pharmaProduct/productBrand/index.vue"),
      meta: {
        pageTitle: "Loại thầu theo QĐ3618",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Loại thầu theo QĐ3618",
            active: true,
          },
        ],
      },
    },
    {
      path: "/bidding-form",
      name: "bidding-form",
      component: () => import("@/views/pharmaProduct/productBrand/index.vue"),
      meta: {
        pageTitle: "Hình thức thầu",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Danh mục",
            active: false,
          },
          {
            text: "Hình thức thầu",
            active: true,
          },
        ],
      },
    },

    // type manager
    {
      path: "/type-manager",
      name: "type-manager",
      component: () => import("@/views/pharmaProduct/productType/index.vue"),
      meta: {
        pageTitle: "Quản lý quy cách đóng gói",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Sản phẩm",
            active: false,
          },
          {
            text: "Quản lý quy cách đóng gói",
            active: true,
          },
        ],
      },
    },
    {
      path: "/prescription-waiting/:id",
      name: "PrescriptionWaiting",
      component: () => import("@/views/prescriptionWaiting/index.vue"),
      meta: {
        pageTitle: "Đơn thuốc chờ xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Danh sách",
            to: "/bill-manager?tab=2",
            active: false,
          },
          {
            text: "Chi tiết",
            active: true,
          },
        ],
      },
    },
    // inventory manager
    {
      path: "/inventory-manager",
      name: "inventory-manager",
      component: () => import("@/views/inventoryManager/index.vue"),
      meta: {
        pageTitle: "Quản lý kho",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý kho",
            active: true,
          },
        ],
      },
    },

    {
      path: "/inventory-manager/add",
      name: "add-inventory",
      component: () => import("@/views/inventoryManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm Kho",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Kho",
            active: false,
            to: "/inventory-manager",
          },
          {
            text: "Thêm Kho",
            active: true,
          },
        ],
      },
    },

    {
      path: "/inventory-manager/update/:id",
      name: "update-inventory",
      component: () => import("@/views/inventoryManager/add/index.vue"),
      meta: {
        pageTitle: "Cập nhật Kho",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Kho",
            active: false,
            to: "/inventory-manager",
          },
          {
            text: "Cập nhật Kho",
            active: true,
          },
        ],
      },
    },
    // Receiving
    {
      path: "/receiving-manager/add",
      name: "add-receiving",
      component: () => import("@/views/receivingManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm phiếu nhập",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý phiếu nhập",
            active: false,
          },
          {
            text: "Thêm phiếu nhập",
            active: true,
          },
        ],
      },
    },
    {
      path: "/bill-manager",
      name: "BillManager",
      component: () => import("@/views/BillManager/index.vue"),
      meta: {
        pageTitle: "Quản lý Nhập/Xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Nhập/Xuất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/receipt-manager",
      name: "ReceiptManager",
      component: () => import("@/views/ReceiptManager/index.vue"),
      meta: {
        pageTitle: "Quản lý Phiếu Thu",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý Phiếu Thu",
            active: true,
          },
        ],
      },
    },
    {
      path: "/receipt-manager/:id",
      name: "ReceiptDetail",
      component: () => import("@/views/ReceiptManager/ReceiptDetail.vue"),
      meta: {
        pageTitle: "Chi tiết Phiếu Thu",
        middleware: [auth],
        layout: "full",
        // breadcrumb: [
        //   {
        //     text: "Quản lý Phiếu Thu",
        //     active: false,
        //     to: "/receipt-manager",
        //   },
        //   {
        //     text: "Chi tiết Phiếu Thu",
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/add-receiving",
      name: "AddReceiving",
      component: () => import("@/views/receivingManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm phiếu nhập",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Thêm phiếu nhập",
            active: true,
          },
        ],
      },
    },
    {
      path: "/add-begin-period",
      name: "AddBeginPeriod",
      component: () =>
        import("@/views/receivingManager/AddBeginPeriod/index.vue"),
      meta: {
        pageTitle: "Nhập Tồn đầu kỳ",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Nhập Tồn đầu kỳ",
            active: true,
          },
        ],
      },
    },
    // Saling
    {
      path: "/saling-manager/add",
      name: "add-saling",
      component: () => import("@/views/salingManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm phiếu xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Quản lý phiếu xuất",
            active: false,
          },
          {
            text: "Thêm phiếu xuất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/add-saling",
      name: "AddSaling",
      component: () => import("@/views/salingManager/add/index.vue"),
      meta: {
        pageTitle: "Thêm phiếu xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Thêm phiếu xuất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/update-saling/:id",
      name: "UpdateSaling",
      component: () => import("@/views/salingManager/add/index.vue"),
      meta: {
        pageTitle: "Cập nhật phiếu xuất",
        middleware: [auth],
        breadcrumb: [
          {
            text: "Cập nhật phiếu xuất",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report-manager",
      name: "ReportManager",
      component: () => import("@/views/ReportManager/index.vue"),
      meta: {
        middleware: [auth],
        pageTitle: "Báo cáo",
        // breadcrumb: [
        //   {
        //     text: "Báo cáo",
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/report-detail/:id",
      name: "ReportDetail",
      component: () => import("@/views/ReportManager/ReportLayout.vue"),
      meta: {
        middleware: [auth],
        layout: "full",
        // pageTitle: "Báo cáo",
        // breadcrumb: [
        //   {
        //     text: "Báo cáo",
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/saling-detail/:id",
      name: "SalingDetail",
      component: () => import("@/views/salingManager/SalingDetail.vue"),
      meta: {
        layout: "full",
        middleware: [auth],
      },
    },
    {
      path: "/receiving-detail/:id",
      name: "ReceivingDetail",
      component: () => import("@/views/receivingManager/ReceivingDetail.vue"),
      meta: {
        layout: "full",
        middleware: [auth],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        middleware: [checkLogin],
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    });
  };
}
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({
      ...context,
      next: nextMiddleware,
    });
  }
  return next();
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
